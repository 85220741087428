.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  padding-right: calc(1rem * var(--tw-space-x-reverse));
  padding-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.menu-button{
  @apply bg-white rounded-none p-2 inline-flex items-center justify-center text-atc-black hover:text-atc-success hover:bg-atc-gray focus:outline-none focus:ring-2 focus:ring-inset focus:ring-atc-success;
}
.menu-items {
  @apply text-atc-accent font-heading font-normal text-[14.5px] tracking-[0.19px] leading-7 md:space-x-4 xl:space-x-[44px] lg:flex flex-wrap hidden;
}
.menu-item {
  @apply hover:text-atc-secondary transition ease-in-out duration-200 uppercase;
}
.quicklink-count--wrapper{
  @apply bg-atc-primary text-atc-white text-xs py-[3px] px-1.5 rounded-full ml-2;
}
.quicklink-count{
  @apply text-center;
}