.form-input,
.form-select {
    @apply
    block
    w-full
    border
    border-gray-300
    bg-white
    focus:outline-none
    rounded-md;
}

.form-select {
    @apply py-2 px-3;
}

.form-label {
    @apply block
    text-gray-700
    mb-2;
}

.panel-grid {
    @apply md:grid
    md:grid-cols-3
    md:gap-6;
}

.panel-section {
    @apply bg-white
    shadow
    px-4
    py-5
    sm:rounded-lg
    sm:p-6;
}

.panel-heading {
    @apply text-xl font-medium leading-6 text-gray-900;
}

.panel-subheading {
    @apply mt-1 text-sm text-gray-500;
}

.form-select {
  @apply pr-6;

  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQCAYAAAAMJL+VAAAABGdBTUEAALGPC/xhBQAAAQtJREFUOBG1lEEOgjAQRalbGj2OG9caOACn4ALGtfEuHACiazceR1PWOH/CNA3aMiTaBDpt/7zPdBKy7M/DCL9pGkvxxVp7KsvyJftL5rZt1865M+Ucq6pyyF3hNcI7Cuu+728QYn/JQA5yKaempxuZmQngOwEaYx55nu+1lQh8GIatMGi+01NwBcEmhxBqK4nAPZJ78K0KKFAJmR3oPp8+Iwgob0Oa6+TLoeCvRx+mTUYf/FVBGTPRwDkfLxnaSrRwcH0FWhNOmrkWYbE2XEicqgSa1J0LQ+aPCuQgZiLnwewbGuz5MGoAhcIkCQcjaTBjMgtXGURMVHC1wcQEy0J+Zlj8bKAnY1/UzDe2dbAVqfXn6wAAAABJRU5ErkJggg==');
  background-size: 0.7rem;
  background-repeat: no-repeat;
  background-position: right 0.7rem center;

  &::-ms-expand {
    @apply opacity-0;
  }
}

.form-input.error,
.form-textarea.error,
.form-select.error {
  @apply border-red-600 focus:ring focus:ring-red-200;
}

.form-error {
  @apply text-red-600 text-xs font-light mt-2;
}

.form-input-edit > label,
.panel-sub-section .order-value,
.form-textarea-edit > label,
.form-select-edit > label,
.form-label-edit {
  @apply text-atc-accent font-heading text-[15px] leading-5 my-3;
}
.form-input-edit > input.error,
.form-textarea-edit > textarea.error,
.form-select-edit > select.error {
  @apply border-red-600;
}
.form-input-edit > input,
.form-textarea-edit > textarea,
.form-select-edit > select,
.form-multiselect-edit > .multiselect__tags {
  @apply text-sm text-atc-accent bg-atc-gray border-atc-white rounded-none focus:border-atc-secondary focus:ring-atc-secondary w-full pl-4;
}
.form-multiselect-edit > .multiselect__tags {
  @apply pt-2;
}
.form-multiselect-edit * > .multiselect__input {
  @apply focus:ring-transparent	bg-atc-gray leading-5 pl-0 pt-1 mb-3;
}

.form-repeater-input > input {
  @apply pr-11;
}
.form-multiselect-edit--big > .multiselect__tags {
  @apply min-h-[8rem];
}
.form-multiselect-edit > .multiselect__content-wrapper {
  @apply drop-shadow min-w-max max-w-[400px];
}
.form-multiselect-edit > .multiselect__select {
  @apply h-[2.8rem];
}
.form-multiselect-edit > .multiselect__select:before {
  border-top-color: rgb(203, 132, 67);
  top: 60%;
}
.form-multiselect-edit * > .multiselect__tag,
.form-multiselect-edit * > .multiselect__single {
  background-color: rgb(230,231,231);
  @apply text-atc-text text-ellipsis overflow-hidden max-w-full rounded-full text-[0.8rem] pl-5 pr-12 py-1 w-auto leading-5;
}
.form-multiselect-edit * > .multiselect__single {
  @apply mb-2;
}
.form-multiselect-edit * > .multiselect__placeholder {
  @apply text-base leading-5 pt-1 mb-3;
}
.form-multiselect-edit * > .multiselect__tag-icon {
  @apply right-4;
}
.form-multiselect-edit * > .multiselect__tag-icon:hover:after {
  @apply text-red-600;
}
.form-multiselect-edit * > .multiselect__tag-icon:after {
  @apply text-[2em] font-light leading-6 duration-200;
}
.form-multiselect-edit * > .multiselect__option {
  @apply border border-gray-100 font-medium duration-200;
}
.form-multiselect-edit * > .multiselect__option--highlight,
.form-multiselect-edit * > .multiselect__option--highlight:after {
  @apply bg-white text-atc-secondary duration-200;
}
.form-multiselect-edit * > .multiselect__option--selected,
.form-multiselect-edit * > .multiselect__option--selected:after,
.form-multiselect-edit * > .multiselect__option--selected.multiselect__option--highlight,
.form-multiselect-edit * > .multiselect__option--selected.multiselect__option--highlight:after {
  @apply bg-atc-secondary text-atc-white;
}
.form-multiselect-edit * > .multiselect__option--selected:hover,
.form-multiselect-edit * > .multiselect__option--selected:hover:after {
  background-color: rgb(230,231,231);
  @apply text-atc-text;
}
.form-multiselect-edit * > .multiselect__placeholder {
  @apply text-gray-400
}
.form-multiselect-error > .multiselect__tags {
  @apply border-red-600 focus:ring focus:ring-red-200 pt-[11px];
}
.form-multiselect-edit.required * > .multiselect__single {
  @apply pr-5
}
.form-input-edit:disabled, .form-input-edit.disabled,
.form-textarea-edit:disabled, .form-textarea-edit.disabled {
  @apply opacity-70 cursor-not-allowed;
}
form .multiselect--disabled{
  @apply cursor-not-allowed;
  pointer-events: all;
}
.multiselect--disabled *{
  pointer-events: none;
}
.multiselect--disabled .multiselect__select{
  @apply hidden;
}
.form-multiselect-edit .multiselect__spinner {
    @apply h-full top-0 -right-[3px];
    background: transparent;
}


.hu-color-picker{
  @apply m-auto;
}
.hu-color-picker .color-set{
  @apply flex flex-row justify-between mb-2;
}
.hu-color-picker .color-type{
  @apply hidden;
}
.color-box{
  @apply w-11 h-11 mr-4;
}

.dp__preset_ranges {
  @apply text-atc-text;
}

/* reCAPTCHA */
.grecaptcha-badge {
    bottom: 68px !important;
}