.carousel.details-carousel,
.details-carousel .details-carousel .carousel__viewport,
.details-carousel .carousel__track,
.details-carousel .carousel__slide,
.carousel__slide img {
    height: 100%;
}

.carousel.details-carousel .carousel__slide {
    padding: 0;
}

.carousel.details-carousel .carousel__viewport {
    padding-bottom: 1rem;
    border-bottom: 1px solid gray;
    min-height: calc(100% - 45px);
}

.details-carousel .carousel__next,
.details-carousel .carousel__prev {
    background: transparent;
    border: none;
    border-radius: 0;
    position: static;
    display: inline-flex;
    margin-top: 1.5rem;
}

.details-carousel .carousel__next {
    margin-left: 2.5rem;
}

.details-carousel .carousel__prev {
    margin-right: 2.5rem;
}

.details-carousel .carousel__next--in-active,
.details-carousel .carousel__prev--in-active {
    opacity: 0;
}


.details-carousel .carousel__track {
    align-items: center;
}

.details-carousel .carousel__viewport {
    display: flex;
    align-items: center;
}