input, select, textarea, button, div, a {
  &:focus, &:active {
    outline: none;
  }
}
html {
  scroll-behavior: smooth;
}

.visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}