.filters {
    @apply flex w-full flex-wrap gap-3 2xl:w-auto 2xl:flex-nowrap;
}
.filters .multiselect {
    @apply w-full md:w-48;
}
.filters-datepicker .dp__main .dp__input_wrap .dp__input_icon {
    @apply hidden;
}
.filters-datepicker .dp__pointer {
    @apply inline-block h-[45.97px] w-full rounded-none border-1 border-atc-white bg-atc-gray p-2 px-4 font-sans text-base text-atc-black md:w-48 lg:w-auto;
}
.filters-datepicker .dp__pointer:hover {
    @apply border-atc-white;
}
.filters-datepicker .dp__pointer::placeholder {
    @apply text-base;
}
.dp__calendar .dp__today {
    @apply border-atc-secondary;
}
.dp__calendar .dp__active_date {
    @apply bg-atc-secondary;
}
.filters .form-input-edit {
    @apply mx-0 w-full md:w-1/2 lg:w-auto;
}
.filters-bulk .form-input-edit {
    @apply mx-0 w-full md:w-1/2 lg:w-auto xl:mr-3;
}
.filters .form-input-edit.ml-0 {
    margin-left: 0;
}
.filters .multiselect__single {
    white-space: nowrap;
}
.filters .multiselect__single span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90px;
}
