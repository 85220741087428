.print .lead-wrapper{
  flex-wrap: wrap;
}
.print .side-panel, .print .main-panel {
  width: 100% !important;
  margin: 0 !important;
}

.print .side-panel-section:first-of-type {
  padding-top: 0;
}

.print .table-title-icon {
  display: none;
}
.print .form-textarea-edit{
  padding-top:40pt;
}
.print .product-notes{
  padding-top:60pt;
}
.print .leads-product-notes{
  padding-top:0;
}
.print .build-info-print{
  padding-top:40pt;
}
.print .multiselect__single {
  background-color: transparent !important;
  padding-left: 0 !important;
}

.print .multiselect__select::before {
  display: none;
}
.print .status-badge-wrapper{
  width: auto;
}

.print .status-badge {
  padding-right: 15px;
  padding-left: 15px;
}

.print .status-badge-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.print .status-badge {
  align-items: center;
  align-content: center;
  padding-bottom: 15px;
}
.print .status-badge-wrapper.inactive {
  display: none;
}

.print .form-textarea-edit textarea {
  height: 200px;
}

.print input::placeholder {
  color: transparent !important;
}

.print .multiselect__placeholder {
  display: none;
}

.print .multiselect__tags {
  padding-top: 0;
}