/* Basic editor styles */

.ProseMirror {
    min-height: 200px;
    @apply pb-4 border-b-2 border-atc-gray;
}

.ProseMirror * {
    @apply text-atc-text;
    line-height: 1.7;
}

.ProseMirror ul,
.ProseMirror ol {
    padding: 0 1rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
}

.ProseMirror ul {
    list-style-type: disc;
}

.ProseMirror ol {
    list-style-type: decimal;
}


.ProseMirror p {
    font-size: 1rem;
    font-weight: 300;

}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
    line-height: 1.2;
    font-weight: bold;
}

.ProseMirror h1 {
    font-size: 1.6rem;
}

.ProseMirror h2 {
    font-size: 1.5rem;
}

.ProseMirror h3 {
    font-size: 1.4rem;
}

.ProseMirror h4 {
    font-size: 1.3rem;
}

.ProseMirror h5 {
    font-size: 1.2rem;
}

.ProseMirror h6 {
    font-size: 1.1rem;
}

.ProseMirror code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
}

.ProseMirror pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;


}

.ProseMirror code {
    color: inherit;
    padding: 0;
    background: none;
    font-size: 0.8rem;
}

.ProseMirror img {
    max-width: 100%;
    height: auto;
}

.ProseMirror blockquote {
    padding-left: 1rem;
    border-left: 2px solid lightgray;
    font-style: italic;
}

.ProseMirror hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
}

.ProseMirror a {
    @apply text-atc-secondary underline;
}

.ProseMirror .video-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.ProseMirror .video-wrapper iframe {
    width: 80%;
    height: 450px;
}

.tiptap-menu {
    @apply mb-4 py-5 sticky top-[-50px] bg-white border-b-2 border-atc-gray z-10;
}

.tiptap-menu .color-button {
    @apply border-1 border-atc-accent text-atc-text rounded-md bg-white mr-2 mb-2;
    padding: 4px 5px;
    display: inline-flex;
    align-items: center;
    transform: translateY(2px);
    cursor: default;
}

.tiptap-menu .color-button input {
    height: 16px;
    cursor: pointer;
}

.tiptap-menu button {
    @apply py-1 px-3 border-1 border-atc-accent text-atc-text rounded-md bg-white mr-2 mb-2;
}

.tiptap-menu .tiptap-menu-top button {
    @apply py-2 px-4 border-1 border-atc-secondary text-white rounded-md bg-atc-secondary mr-2 mb-2;
}


.tiptap-menu button.is-active {
    @apply text-white bg-atc-accent;
}