@font-face {
    font-family: 'Segue UI';
    src: url('/assets/fonts/seguibl.ttf');
}
@font-face {
    font-family: 'Viga';
    src: url('/assets/fonts/Viga-Regular.ttf');
}
@font-face {
    font-family: 'Ubuntu';
    src: url('/assets/fonts/Ubuntu-Regular.ttf');
    font-weight: 400;
}
@font-face {
    font-family: 'Ubuntu';
    src: url('/assets/fonts/Ubuntu-Medium.ttf');
    font-weight: 500;
}
@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy-SemiBold.ttf');
    font-weight: 600;
}
