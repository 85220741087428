.btn-indigo {
  @apply px-6 py-3 rounded bg-indigo-600 text-white text-sm leading-4 font-bold whitespace-nowrap hover:bg-orange-400 focus:bg-orange-400;
}

.btn-spinner,
.btn-spinner:after {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
}

.btn-spinner {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.2em solid white;
  border-right: 0.2em solid white;
  border-bottom: 0.2em solid white;
  border-left: 0.2em solid transparent;
  transform: translateZ(0);
  animation: spinning 1s infinite linear;
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.configurator-menu-link {
  @apply text-atc-text text-center text-xs md:text-sm xl:px-5 2xl:px-9 flex items-center lg:border-b-6 border-b-transparent relative after:content-[''] after:h-[70%] after:w-[1px]  after:bg-atc-gray after:absolute  after:right-0 ;
}
.configurator-menu-link a {
  @apply flex items-center h-full py-2  px-2 xl:py-0 xl:px-0;
}


.configurator-menu-link--active {
  @apply border-b-atc-primary text-atc-primary;
}


.form-button,
.form-submit-button,
.form-red-button {
  @apply px-6 py-3 uppercase font-medium text-sm shadow-sm duration-200;
}
.form-button {
  @apply border border-gray-300 hover:bg-gray-50 text-atc-text cursor-pointer;
}
.form-submit-button {
  @apply bg-atc-success text-atc-white hover:bg-atc-secondary;
}
.form-red-button {
  @apply bg-atc-error text-atc-white hover:bg-atc-secondary;
}
.news-button{
  @apply bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-atc-secondary;
}