:root {
    --fc-neutral-bg-color: theme('colors.atc.white');
    --fc-border-color: #E3E3E5;

    --fc-button-text-color: theme('colors.atc.black');
    --fc-button-bg-color: transparent;
    --fc-button-border-color: theme('colors.gray.300');
    --fc-button-hover-bg-color: transparent;
    --fc-button-hover-border-color: #626263;
    --fc-button-active-bg-color: transparent;
    --fc-button-active-border-color: #626263;

    --fc-non-business-color: rgba(232, 232, 240, 0.3);
    --fc-bg-event-color: #F8F8FA;
    --fc-bg-event-opacity: 1;
    --fc-today-bg-color: #EDF0EB;
    --fc-now-indicator-color: theme('colors.atc.secondary');

    /* Custom */
    --fc-timegrid-slot-height: 56px;
}

/* Toolbar */
.fc .fc-toolbar.fc-header-toolbar {
    @apply px-4 sm:px-6 lg:px-8 mb-3.5;
}
.fc .fc-toolbar-title {
    @apply text-atc-text text-lg 3xl:text-xl font-normal font-sans uppercase;
}
.fc .fc-view-harness {
    @apply mt-2.5;
}

/* Buttons */
.fc .fc-toolbar-chunk div {
    @apply flex gap-1.5 3xl:gap-3 items-center;
}
.fc .fc-toolbar-chunk {
    @apply flex;
}
.fc .fc-button {
    @apply p-1 3xl:p-2 rounded-none uppercase shadow-sm duration-200 font-medium text-sm 3xl:text-base leading-4 3xl:leading-4;
}
/* Buttons left */
.fc .fc-toolbar-chunk:first-child {
    @apply xl:min-w-[307px] 3xl:min-w-[415px];
}
.fc .fc-toolbar-chunk .fc-button.fc-create-button {
    @apply !flex p-2.5 3xl:p-4 whitespace-nowrap;
}
.fc .fc-toolbar-chunk .fc-button.fc-filters-button {
    @apply flex p-1 border-0 shadow-none hover:bg-transparent;
}
.fc .fc-toolbar-chunk .fc-button svg {
    @apply mr-[0.5rem] w-[0.94rem];
}
/* Buttons center */
.fc .fc-toolbar-chunk div:not(.fc-button-group) {
    @apply gap-8;
}
.fc .fc-button .fc-icon {
    @apply block text-[21px];
}
/* Buttons right */
.fc .fc-today-button {
    @apply p-2.5 3xl:p-4;
}
.fc .fc-toolbar-chunk:last-child .fc-button-group {
    @apply bg-atc-gray p-1 3xl:p-2 ml-2 3xl:ml-3;
}
.fc .fc-toolbar-chunk:last-child .fc-button-group .fc-button {
    @apply px-4 3xl:px-6 py-1.5 3xl:py-2 bg-atc-gray border-0 shadow-none;
}
.fc .fc-toolbar-chunk:last-child .fc-button-group .fc-button:hover {
    @apply rounded-sm bg-[#D9D9D9];
}
.fc .fc-toolbar-chunk:last-child .fc-button-group .fc-button.fc-button-active {
    @apply rounded-sm bg-atc-white;
    box-shadow: 0 0 6px 0 rgba(128, 128, 128, 0.20);
}
.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:focus,
.fc .fc-button:focus {
    @apply shadow-none;
}

/* Grid */
.fc .fc-scrollgrid-section td {
    @apply border-b-0;
}
.fc .fc-timeGridWeek-view .fc-col-header-cell.fc-day {
    @apply hover:border-b-2 hover:border-b-atc-accent hover:bg-atc-gray transition ease-in-out duration-200;
}
.fc-scrollgrid-section.fc-scrollgrid-section-header .fc-col-header-cell-cushion {
    @apply w-full text-atc-text text-sm font-normal uppercase py-[18px];
}
.fc .fc-col-header-cell.fc-day-today {
    @apply border-b-2 border-b-atc-primary;
    background-color: var(--fc-today-bg-color);
}

.fc .fc-timegrid .fc-scrollgrid {
    @apply border-l-0;
}
.fc .fc-timegrid-slots tbody tr:first-child .fc-timegrid-slot-label {
    @apply hidden;
}
.fc .fc-timegrid-slot-label {
    @apply align-top border-l-0 text-xs uppercase;
}
.fc .fc-timegrid-slot-label-frame {
    @apply bg-atc-white relative -top-2.5 mr-[20px];
}
.fc .fc-timegrid-slot-label-cushion {
    @apply pl-3 pr-2.5;
}
.fc .fc-timegrid-slot {
    @apply h-[var(--fc-timegrid-slot-height)];
}
.fc .fc-non-business {
    @apply bg-disabled-hours-pattern;
}
.fc .fc-timegrid-event {
    @apply border-[1.5px] shadow-none;
}

.fc .fc-daygrid-day {
    @apply h-[120px];
}
.fc .fc-daygrid-day.fc-day-today {
    @apply border-2 border-atc-accent;
}
.fc .fc-daygrid-day-top {
    @apply p-1;
}
.fc .fc-daygrid-day-number {
    @apply p-2 text-atc-text font-normal rounded-full hover:bg-atc-gray;
}
.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
    @apply grid gap-1 -top-10 p-3 m-0;
}
.fc .fc-daygrid-day-events:before,
.fc .fc-daygrid-day-events:after {
    @apply hidden;
}
.fc .fc-daygrid-dot-event:hover,
.fc .fc-daygrid-dot-event:focus {
    background: rgba(0, 0, 0, 0.045);
}
.fc .fc-daygrid-event {
    @apply m-0 p-0 h-5;
}
.fc .fc-daygrid-more-link.fc-more-link {
    @apply w-full text-sm font-medium text-atc-text pl-1 py-0 m-0;
}
.fc .fc-daygrid-more-link.fc-more-link:hover {
    background: rgba(0, 0, 0, 0.045);
}

/* Events */
.fc .fc-event {
    @apply cursor-pointer;
}
.fc .fc-bg-event {
    @apply p-3 pointer-events-none;
    background-image: url('/assets/images/diagonal-stripes-pattern.png');
}

/* Popover */
.fc .fc-popover-header {
    @apply pl-8 pr-4 py-4 border-b-[1px] items-start;
    border-color: var(--fc-border-color);
}
.fc .fc-popover-title {
    @apply text-base leading-7 font-medium uppercase;
}
.fc .fc-more-popover .fc-popover-body {
    @apply px-8 py-4 grid gap-1;
}

.fc a[data-navlink] {
    @apply transition ease-in-out duration-200 hover:no-underline;
}



/**
 * Fully expanded "now" indicator line
 * @see https://github.com/fullcalendar/fullcalendar/issues/4609#issuecomment-1010706978
 */
.fc-timeGridWeek-view .fc-timegrid-now-indicator-container {
    overflow: visible !important;
}
.fc-timeGridWeek-view .fc-timegrid-now-indicator-line {
    width: calc(700% + 6px) !important;
}
.fc-timeGridWeek-view .fc-day:nth-child(2) .fc-timegrid-now-indicator-line {
    --fc-now-indicator-shift: 0%;
}
.fc-timeGridWeek-view .fc-day:nth-child(3) .fc-timegrid-now-indicator-line {
    --fc-now-indicator-shift: 14.29%;
}
.fc-timeGridWeek-view .fc-day:nth-child(4) .fc-timegrid-now-indicator-line {
    --fc-now-indicator-shift: 28.57%;
}
.fc-timeGridWeek-view .fc-day:nth-child(5) .fc-timegrid-now-indicator-line {
    --fc-now-indicator-shift: 42.86%;
}
.fc-timeGridWeek-view .fc-day:nth-child(6) .fc-timegrid-now-indicator-line {
    --fc-now-indicator-shift: 57.14%;
}
.fc-timeGridWeek-view .fc-day:nth-child(7) .fc-timegrid-now-indicator-line {
    --fc-now-indicator-shift: 71.43%;
}
.fc-timeGridWeek-view .fc-day:nth-child(8) .fc-timegrid-now-indicator-line {
    --fc-now-indicator-shift: 85.71%;
}
.fc-timeGridWeek-view .fc-day .fc-timegrid-now-indicator-line {
    transform: translateX(calc(-1 * var(--fc-now-indicator-shift)));
}



/* Reservation details modal */
.reservation-details-button {
    @apply flex border-[1px] rounded-sm border-[#CBD5E1] text-[17px] py-2 px-5 leading-tight transition duration-200;
}
.reservation-details-button:hover {
    border-color: var(--fc-button-hover-border-color);
}
