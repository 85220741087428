.table{
  @apply table-fixed w-full min-w-min divide-y-4 divide-atc-white text-atc-text;
}
.table-record-list{
  min-width:1000px;
}
.table-head{
  @apply bg-atc-gray;
}
.table-body{
  @apply divide-y-2 divide-atc-gray bg-atc-white;
}
.table-cell{
  @apply whitespace-nowrap py-4 px-3 text-sm font-normal sm:px-4 text-ellipsis overflow-hidden;
}
.table-cell .status-badge {
  @apply cursor-auto text-[0.86rem] py-[7px] font-medium my-[3px];
}
.table-row:hover .table-cell {
  @apply bg-gray-50;
}
.table-header{
  @apply py-4 px-3 text-left text-base font-heading font-normal sm:px-4;
}
.table-row td.table-cell:last-child, .table-row th:last-child{
  @apply text-center;
}
.table-title{
  @apply text-atc-text font-heading text-[1.17rem] uppercase;
}
.table-title-icon{
  @apply w-[1.175rem] mx-2 relative bottom-[0.5px];
}
.table-controls-view-btn{
  @apply pl-3 border-atc-accent cursor-pointer flex justify-center hover:text-atc-secondary duration-200;
}
.table-controls-view-icon{
  @apply ml-[0.5rem] w-[0.94rem] relative bottom-[0.75px] -rotate-90;
}
.table-controls-create-btn{
  @apply px-3 cursor-pointer flex justify-center hover:text-atc-secondary duration-200;
}
.table-controls-create-icon{
  @apply mr-[0.5rem] w-[0.94rem] relative bottom-[1px];
}
.filters-search{
  @apply border border-atc-graphite rounded text-atc-text placeholder-atc-text mx-3 mt-1 h-10 focus:border-atc-secondary focus:ring-atc-secondary;
}
