/* Reset */
@import 'tailwindcss/base';
@import 'css/reset.css';

/* Components */
@import 'tailwindcss/components';
@import 'tailwindcss/confirm-modal.css';
@import 'css/overrides/charts.css';
@import 'css/overrides/datepicker.css';
@import 'css/overrides/fullcalendar.css';
@import 'css/overrides/tiptap.css';
@import 'css/overrides/vue-final-modal.css';
@import 'css/buttons.css';
@import 'css/carousel.css';
@import 'css/filters.css';
@import 'css/form.css';
@import 'css/print.css';
@import 'css/sidepanel.css';
@import 'css/table.css';
@import 'css/topmenu.css';

/* Utilities */
@import 'tailwindcss/utilities';

@import 'assets/fonts/fonts.css';
